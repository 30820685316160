// eslint-disable-next-line
export default  {
    apiKey: "AIzaSyD8_Z7ktDSLD_X_PtgmLokQzqkbWO-Xifk",
    authDomain: "creativecentury-a6089.firebaseapp.com",
    projectId: "creativecentury-a6089",
    storageBucket: "creativecentury-a6089.appspot.com",
    messagingSenderId: "591075589764",
    appId: "1:591075589764:web:26d70b395d470bfdd28cd1",
    measurementId: "G-QTD9NJMWPM"
}
