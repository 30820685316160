import React, {ReactElement} from "react";
import {Box} from "@mui/material";
import {tokens,} from "../theme";

type CircleProps = {
    border?: number;
    radius?: number;
    backgroundColor?: string;
    children?: ReactElement<any>;
    height?: number;
    width?: number;
    borderColor?: string;
    boxShadow?: number;
    mx?: number;
    p?: number;
}

const Circle = (props: CircleProps): ReactElement => {
    const colors = tokens();
    const {
        border= 5,
        radius,
        backgroundColor,
        borderColor = colors.white[700],
        children,
        height=50 ,
        width=50 ,
        boxShadow=0,
        mx=0,
        p=0,
    } = props;

    return (
        <Box
            sx={{ backgroundColor, borderColor }}
            alignItems="center"
            display="flex"
            justifyContent="center"
            height={height}
            width={width}
            border={border}
            borderRadius={radius}
            boxShadow={boxShadow}
            mx={mx}
            p={p}
        >
            {children}
        </Box>
    )
}

export default Circle;
