import React, {ReactElement} from "react";
import {Box, Typography} from "@mui/material";
import {tokens,} from "../theme";
import Circle from "./Circle";

interface IProps {
    name: string;
    value: number;
}

const Summary= (props: IProps): ReactElement => {
    const {name, value} = props;
    const colors =  tokens();
    const bkColor = (): string => {
        if (name.toLowerCase() === 'total') {
            return colors.primary[800];
        }
        if (name.toLowerCase() === 'in') {
            return colors.green[800];
        }
        if (name.toLowerCase() === 'out') {
            return colors.red[800];
        }
        return colors.blue[100];
    }
    return (
        <Box
            key={name}
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
        >
            <Circle
                border={0}
                height={40}
                width={40}
                radius={20}
                backgroundColor={bkColor()}
                borderColor={colors.white[700]}
                //mx={2}
                //p={isMobile ? 2.5 : 4.5}
            >
                <Typography variant="h6" fontWeight={"bold"} fontSize={12} color={colors.white[100]}>
                    {value}
                </Typography>
            </Circle>
            <Box mt={2} alignItems="center" display="center" justifyContent="center">
                <Typography align="center" variant="h6" fontSize={14} color={colors.black[900]}>
                    {name}
                </Typography>
            </Box>
        </Box>
    )
}

export default Summary;
