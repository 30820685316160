import React, { useEffect, useState, ReactElement } from "react";
import {Box, Chip, Paper, Stack, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {IAppData, IBranch, ISummary} from "../types";
import Summary from "../components/Summary";
import Branch from "../components/Branch";
import {tokens,} from "../theme";
import LoadingOverlay from "../components/LoadingOverlay";
import { onSnapshot, collection, getDocs } from "firebase/firestore";
import {db} from "../firebase";
import useCheckIsMobile from "../hooks/useCheckIsMobile";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const colors =  tokens();
const LEGEND = [
    { name: 'TOTAL', textColor: '#fff', bgColor: colors.primary[800] },
    { name: 'IN OFFICE', textColor: '#fff', bgColor: 'green' },
    { name: 'MISSING IN OFFICE', textColor: '#fff', bgColor: 'red' },
    { name: 'Unknown', textColor: '#000', bgColor: colors.blue[100] },
    { name: 'PART TIMER', textColor: '#fff', bgColor: 'purple' },
    { name: 'CLOSED WITH LATE CHECK IN', textColor: '#fff', bgColor: 'orange' },
    { name: 'ANNUAL LEAVE', textColor: '#fff', bgColor: 'purple' },
    { name: 'MATERNITY LEAVE', textColor: '#fff', bgColor: 'black' },
]

export default function Home(): ReactElement {
    const [appData, setAppData] = useState<IAppData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile: boolean = useCheckIsMobile();
    const [updatedDate, setUpdatedDate] = useState<Date>(new Date());


    const massageReturnedData = (snapshot: any): IAppData => {
        let IN_COUNT: number = 0, OUT_COUNT = 0, UNKNOWN_COUNT = 0,
            BRANCHES: IBranch[] = [];
        snapshot.forEach((doc: any) => {
            if (doc.data()?.state?.find((i: any) => i?.state === 'in'))
                IN_COUNT++;
            else if (!doc.data()?.state?.find((i: any) => i?.state === 'in'))
                OUT_COUNT++;
            else
                UNKNOWN_COUNT++;
            BRANCHES.push(doc.data() as any)
        });
        return {
            branches: BRANCHES,
            summary: [
                {name: 'Total', value: BRANCHES.length},
                {name: 'In', value: IN_COUNT},
                {name: 'Out', value: OUT_COUNT},
                {name: 'Unknown', value: UNKNOWN_COUNT},
            ]
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const querySnapshot: any = await getDocs(collection(db, "branches"));
                const data: IAppData = massageReturnedData(querySnapshot);
                setLoading(false);
                setUpdatedDate(new Date());
                return setAppData(data);
            } catch (e: any) {
                console.error(e);
            }
        }

       fetchData();

        const unsubscribe = onSnapshot(collection(db,"branches"), (snapshot) => {
            const data: IAppData = massageReturnedData(snapshot);
            setUpdatedDate(new Date());
            return setAppData(data);
        }, (error: any) => {
            console.error('Error fetching collection:', error);
        });

        // Unsubscribe from collection listener when component unmounts
        return () => {
            unsubscribe();
        };
    }, []);

    const formatDate = (date: Date): string => {
        const year: number = date.getFullYear();
        const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day: string = String(date.getDate()).padStart(2, '0');
        const hours: string = String(date.getHours()).padStart(2, '0');
        const minutes: string = String(date.getMinutes()).padStart(2, '0');
        const seconds: string = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    if (loading) {
        return <LoadingOverlay open={loading} />;
    }

    return (
        <Box p="20px" sx={{ backgroundColor: '#f0f0f0'}}>
            <Paper elevation={3} square>
                <Grid2 container spacing={2} sx={{ backgroundColor: '#fbfbfe'}}>
                    <Grid2 xs={12} md={8}>
                        {LEGEND.map((i: any, v: number) => {
                            return (
                                <Chip
                                    key={v}
                                    label={i?.name}
                                    sx={{
                                        paddingX: 2,
                                        color: i?.textColor,
                                        fontSize: 24,
                                        fontWeight: 'bolder',
                                        backgroundColor: i?.bgColor,
                                        marginX: 1,
                                        marginY: 0.5
                                    }}
                                />
                            )
                        })}
                    </Grid2>
                    <Grid2
                        xs={12} md={1.3}
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                        sx={{ border: '2px solid', marginY: 2}}
                    >
                        <Typography variant="caption" fontSize={15}>Last Activity:</Typography>
                        <Typography variant="h5" fontSize={20} fontWeight="bolder">{formatDate(updatedDate)}</Typography>
                    </Grid2>
                    <Grid2 xs={12} md={2} justifyContent="center" display="flex">
                        <Stack direction="row" spacing={2}>
                            {appData?.summary.map((i: ISummary) => {
                                return <Summary key={i?.name} name={i?.name} value={i?.value} />
                            })}
                        </Stack>
                    </Grid2>
                    {!isMobile ? (
                        <Grid2
                            xs={12} md={0.7}
                            justifyContent="center"
                            display="flex"
                            alignItems="center"
                        >
                            <Stack direction="row" spacing={2}>
                                <ZoomInIcon
                                    sx={{ fontSize: 50 }}
                                    onClick={() => {
                                        const currentZoom: string = (document.body.style as any).zoom;
                                        const zoomNumber: number = Number(currentZoom.replace('%', ''));
                                        return (document.body.style as any).zoom = `${String(zoomNumber + 5)}%`;
                                    }}
                                />
                                <ZoomOutIcon
                                    sx={{ fontSize: 50 }}
                                    onClick={() => {
                                        const currentZoom: string = (document.body.style as any).zoom;
                                        const zoomNumber: number = Number(currentZoom.replace('%', ''));
                                        if (zoomNumber > 0)
                                            return (document.body.style as any).zoom = `${String(zoomNumber - 5)}%`;
                                    }}
                                />
                            </Stack>
                        </Grid2>
                    ) : null}
                </Grid2>
            </Paper>
            <Box my={2}>
                <Grid2 container spacing={1} alignItems="stretch">
                    {appData?.branches.map(i => {
                        return <Branch key={i?.name} name={i.name} state={i.state} />
                    })}
                </Grid2>
            </Box>
        </Box>
    )
}
