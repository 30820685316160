import React from "react";
import {IState} from "../types";
import {Card, CardContent, Stack, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Circle from "./Circle";
import {tokens} from "../theme";

type IProps = {
    name: string;
    state: IState[];
}
type TBgColors = {
    outer: string;
    text: string;
}

const Branch = (props: IProps) => {
    const {name, state} = props;
    const colors = tokens();

    const backgroundColors = (): TBgColors=> {
        if (!state?.find(i => i?.state === 'in')) {
            return {
                outer: state?.find(i => i?.latecheckin === true) ? 'orange' : colors.red[700],
                text: colors.white[100],
            }
        }
        if (state?.find(i => i?.state === 'in')) {
            return {
                outer: colors.green[700],
                text: colors.white[100],
            }
        }
        if (state?.find(i => i?.state === 'al')) {
            return {
                outer: colors.red[700],
                text: colors.white[100],
            }
        }
        return {
            outer: colors.blue[100],
            text: colors.white[100],
        }
    }

    const bgColors:TBgColors = backgroundColors();

    const getStaffBackgroundColor = (index: number): string => {
        if (state === null) return 'white'
        if (state.length === 1 && index === 1) {
            return 'red';
        }
        if (state[index]?.state === 'in') {
            return 'green';
        }
        if (state[index]?.state === 'out') {
            return 'red';
        }
        if (state[index]?.state === 'al') {
            return 'purple'
        }
        if (state[index]?.state === 'ml') {
            return 'black'
        }
        return 'white';
    }

    const getStaffName = (index: number): string => {
        if (state === null) return 'M'
        if (state.length === 1 && index === 1)
            return 'X';
        if (state[index]?.position === 'assistantManager')
            return 'AM';
        if (state[index]?.position === 'partTimer')
            return 'PT';
        return 'M';
    }

    return (
        <Grid2 key={name} xs={6} md={3} sm={4} lg={1.5} xl={1}>
            <Card sx={{ backgroundColor: bgColors.outer, padding: 0 }}>
                <CardContent
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 170,
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="body2"
                        color={bgColors.text}
                        fontWeight="bold"
                        component="div"
                        textTransform="uppercase"
                        //pb={2}
                    >
                        {name || ''}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        {state?.map((i,v) => {
                            return (
                                <Circle
                                    key={v}
                                    height={60}
                                    width={60}
                                    radius={60}
                                    backgroundColor={getStaffBackgroundColor(v)}
                                    border={0}
                                    boxShadow={8}
                                >
                                    <Typography color={'white'} fontSize={20} fontWeight="bolder">
                                        {getStaffName(v)}
                                    </Typography>
                                </Circle>
                            )
                        })}
                    </Stack>
                </CardContent>
            </Card>
        </Grid2>
    )
}

export default Branch;
