import React, {useEffect} from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./screens/Home";
import {theme} from "./theme";

function App() {
    useEffect(() => {
        (document.body.style as any).zoom = '65%';

        return () => {
            // Reset zoom level when component unmounts
            (document.body.style as any).zoom = '';
        };
    }, []);
      return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter basename="/">
                {/*<AppBarComponent />*/}
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
          </ThemeProvider>
      );
}

export default App;
