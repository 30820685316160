import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import FIREBASE_CONFIG from './firebase_config';

// Firebase configuration
const firebaseConfig = {
    apiKey: FIREBASE_CONFIG.apiKey,
    authDomain: FIREBASE_CONFIG.authDomain,
    projectId: FIREBASE_CONFIG.projectId,
    storageBucket: FIREBASE_CONFIG.storageBucket,
    messagingSenderId: FIREBASE_CONFIG.messagingSenderId,
    appId: FIREBASE_CONFIG.appId,
    measurementId: FIREBASE_CONFIG.measurementId,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
const db = getFirestore(app);


export { db }
